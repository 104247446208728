exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-business-process-automation-platform-js": () => import("./../../../src/pages/projects/business-process-automation-platform.js" /* webpackChunkName: "component---src-pages-projects-business-process-automation-platform-js" */),
  "component---src-pages-projects-inobi-js": () => import("./../../../src/pages/projects/inobi.js" /* webpackChunkName: "component---src-pages-projects-inobi-js" */),
  "component---src-pages-projects-smart-taxi-js": () => import("./../../../src/pages/projects/smart-taxi.js" /* webpackChunkName: "component---src-pages-projects-smart-taxi-js" */),
  "component---src-ui-templates-project-js-content-file-path-src-content-projects-food-truck-food-truck-mdx": () => import("./../../../src/ui/templates/project.js?__contentFilePath=/Users/irrvrsl/Documents/GitHub/avisatech-website/src/content/projects/food-truck/food-truck.mdx" /* webpackChunkName: "component---src-ui-templates-project-js-content-file-path-src-content-projects-food-truck-food-truck-mdx" */),
  "component---src-ui-templates-project-js-content-file-path-src-content-projects-food-truck-privacy-policy-mdx": () => import("./../../../src/ui/templates/project.js?__contentFilePath=/Users/irrvrsl/Documents/GitHub/avisatech-website/src/content/projects/food-truck/privacy-policy.mdx" /* webpackChunkName: "component---src-ui-templates-project-js-content-file-path-src-content-projects-food-truck-privacy-policy-mdx" */),
  "component---src-ui-templates-project-js-content-file-path-src-content-projects-keeback-keeback-mdx": () => import("./../../../src/ui/templates/project.js?__contentFilePath=/Users/irrvrsl/Documents/GitHub/avisatech-website/src/content/projects/keeback/keeback.mdx" /* webpackChunkName: "component---src-ui-templates-project-js-content-file-path-src-content-projects-keeback-keeback-mdx" */),
  "component---src-ui-templates-project-js-content-file-path-src-content-projects-no-limit-generation-nlg-mdx": () => import("./../../../src/ui/templates/project.js?__contentFilePath=/Users/irrvrsl/Documents/GitHub/avisatech-website/src/content/projects/no-limit-generation/nlg.mdx" /* webpackChunkName: "component---src-ui-templates-project-js-content-file-path-src-content-projects-no-limit-generation-nlg-mdx" */),
  "component---src-ui-templates-project-js-content-file-path-src-content-projects-nuska-nuska-mdx": () => import("./../../../src/ui/templates/project.js?__contentFilePath=/Users/irrvrsl/Documents/GitHub/avisatech-website/src/content/projects/nuska/nuska.mdx" /* webpackChunkName: "component---src-ui-templates-project-js-content-file-path-src-content-projects-nuska-nuska-mdx" */),
  "component---src-ui-templates-project-js-content-file-path-src-content-projects-oca-oca-mdx": () => import("./../../../src/ui/templates/project.js?__contentFilePath=/Users/irrvrsl/Documents/GitHub/avisatech-website/src/content/projects/oca/oca.mdx" /* webpackChunkName: "component---src-ui-templates-project-js-content-file-path-src-content-projects-oca-oca-mdx" */),
  "component---src-ui-templates-project-js-content-file-path-src-content-projects-senticam-how-to-delete-account-mdx": () => import("./../../../src/ui/templates/project.js?__contentFilePath=/Users/irrvrsl/Documents/GitHub/avisatech-website/src/content/projects/senticam/how-to-delete-account.mdx" /* webpackChunkName: "component---src-ui-templates-project-js-content-file-path-src-content-projects-senticam-how-to-delete-account-mdx" */),
  "component---src-ui-templates-project-js-content-file-path-src-content-projects-senticam-privacy-policy-mdx": () => import("./../../../src/ui/templates/project.js?__contentFilePath=/Users/irrvrsl/Documents/GitHub/avisatech-website/src/content/projects/senticam/privacy-policy.mdx" /* webpackChunkName: "component---src-ui-templates-project-js-content-file-path-src-content-projects-senticam-privacy-policy-mdx" */),
  "component---src-ui-templates-project-js-content-file-path-src-content-projects-senticam-senticam-mdx": () => import("./../../../src/ui/templates/project.js?__contentFilePath=/Users/irrvrsl/Documents/GitHub/avisatech-website/src/content/projects/senticam/senticam.mdx" /* webpackChunkName: "component---src-ui-templates-project-js-content-file-path-src-content-projects-senticam-senticam-mdx" */),
  "component---src-ui-templates-project-js-content-file-path-src-content-projects-yiyk-yiyk-mdx": () => import("./../../../src/ui/templates/project.js?__contentFilePath=/Users/irrvrsl/Documents/GitHub/avisatech-website/src/content/projects/yiyk/yiyk.mdx" /* webpackChunkName: "component---src-ui-templates-project-js-content-file-path-src-content-projects-yiyk-yiyk-mdx" */)
}

